<template>
  <div class="container-fluid">
    <div class="d-sm-flex align-items-center justify-content-between mb-4">
      <h1 class="h3 mb-0 text-gray-800">Manage Bookings</h1>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card shadow mb-4">
          <a href="#collapseUnassignedBookings" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseUnassignedBookings">
            <h6 class="m-0 font-weight-bold text-primary">Unassigned Bookings</h6>
          </a>
          <div class="collapse" id="collapseUnassignedBookings">
            <div class="card-body">
              <DataTable
                :columns="unassignedColumns"
                :options="options"
                :data="unassignedBookings"
                class="display nowrap"
                width="100%"
                @draw="initUnassignedBookings"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="row">
      <div class="col-lg-12">
        <div class="card shadow mb-4">
          <a href="#collapseAssignedBookings" class="d-block card-header py-3" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="collapseAssignedBookings">
            <h6 class="m-0 font-weight-bold text-primary">Assigned Bookings</h6>
          </a>
          <div class="collapse" id="collapseAssignedBookings">
            <div class="card-body">
              <DataTable
                :columns="assignedColumns"
                :options="options"
                :data="assignedBookings"
                class="display nowrap"
                width="100%"
                @draw="initAssignedBookings"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from 'datatables.net-vue3';
import DataTablesLib from 'datatables.net';
import 'datatables.net-responsive';
import moment from 'moment';
import { fetchAssignedBookings, fetchUnassignedBookings } from '@/utils/booking';
import { format as dateFnsFormat } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

DataTable.use(DataTablesLib);

export default {
  name: 'AdminCalendarBookings',
  components: {
    DataTable,
  },
  data() {
    return {
      assignedBookings: [],
      unassignedBookings: [],
      assignedColumns: [
        { 
          title: 'Assigned Date/Time', 
          data: null, 
          render: (data, type, row) => {
            return `${row.startDate || ''} ${row.startTime || ''} <br /> ${row.endDate || ''} ${row.endTime || ''}`;
          } 
        },
        { title: 'First Name', data: 'firstName' },
        { title: 'Last Name', data: 'lastName' },
        { title: 'Competition', data: 'competition.eventName' },
        { title: 'Services', data: 'services' },
        { title: 'Assigned User', data: 'assignedUser.firstName' },
        /*{ 
          title: 'Booking Date', 
          data: 'bookingCreateDate', 
          render: (data) => moment(data).format('YYYY-MM-DD h:mm a'), 
        },*/
        { title: 'Actions', data: '_id', render: (data) => `<button class="btn btn-primary" onclick="navigateToManage('${data}')">Manage</button>`},
      ],
      unassignedColumns: [
        { 
          title: 'Booking Date', 
          data: 'bookingCreateDate', 
          render: (data) => moment(data).format('YYYY-MM-DD h:mm a'),
        },
        { title: 'First Name', data: 'firstName' },
        { title: 'Last Name', data: 'lastName' },
        { title: 'Competition', data: 'competition.eventName' },
        { title: 'Services', data: 'services' },
        { title: 'Actions', data: '_id', render: (data) => `<button class="btn btn-primary" onclick="navigateToManage('${data}')">Manage</button>`},
      ],
      options: {
        dom: 'Bftip',
        responsive: true,
        order: [[0, 'asc']],
      },
      dtObj: {
        unassigned: {},
        assigned: {},
      },
    };
  },
  async created() {
    await this.loadBookings();
    window.navigateToManage = this.navigateToManage; // Bind navigateToManage to window
  },
  methods: {
    async loadBookings() {
      this.unassignedBookings = await fetchUnassignedBookings();
      this.assignedBookings = await fetchAssignedBookings();
    },
    navigateToManage(id) {
      this.$router.push(`/admin/calendar_bookings/edit/${id}`);
    },
    formatDateTime(dateTime) {
      const timeZone = 'America/New_York';
      const zonedDate = toZonedTime(dateTime, timeZone);
      return dateFnsFormat(zonedDate, 'yyyy-MM-dd hh:mm a');
    },
    redrawTable(args) {

      args.dt.trigger('responsive-resize', {
        e: args.event,
        datatable: args.dt,
        columns: [true, true, true, true, true, true, false],
      });

      args.dt.trigger('column-sizing', {
        e: args.event, 
        settings: args.settings, 
      });
    },
    initUnassignedBookings(ev, settings) {
      this.dtObj.unassigned.event = ev.event;
      this.dtObj.unassigned.dt = ev.event.dt;
      this.dtObj.unassigned.settings = settings;
    },
    initAssignedBookings(ev, settings) {
      this.dtObj.assigned.event = ev.event;
      this.dtObj.assigned.dt = ev.event.dt;
      this.dtObj.assigned.settings = settings;
    },
  },
  mounted() {
    //This is a bit hackish but the only way i found to redraw the DataTable which fixes the mobile/responsive issues
    const collapseUnassigneElement = document.getElementById('collapseUnassignedBookings');
    const collapseAssignedElement = document.getElementById('collapseAssignedBookings');

    // Callback function to execute when mutations are observed
    const callback = (mutationsList) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const targetElement = mutation.target;

          if (targetElement.classList.contains('show')) {
            if (targetElement === collapseUnassigneElement) {
              this.redrawTable(this.dtObj.unassigned);
            } else if (targetElement === collapseAssignedElement) {
              this.redrawTable(this.dtObj.assigned);
            }
          }
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target element for configured mutations
    observer.observe(collapseUnassigneElement, { attributes: true, attributeFilter: ['class'] });
    observer.observe(collapseAssignedElement, { attributes: true, attributeFilter: ['class'] });

    // Store observer reference for cleanup
    this.observer = observer;
  },
  beforeUnmount() {
    // Disconnect the observer when the component is unmounted
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>

<style>
#collapseUnassignedBookings table.dataTable tbody td,
#collapseAssignedBookings table.dataTable tbody td {
  font-size: 0.8rem;
}
</style>

