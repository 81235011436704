const fetchAssignedBookings = async() => {
  const token = localStorage.getItem('token');
  const response = await fetch('/api/booking/assigned', {
    method: 'GET',
    headers: {
      'Authorization': token ? `Bearer ${token}` : '',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch assigned bookings');
  }
  return response.json();
};

const fetchUnassignedBookings = async() => {
  const token = localStorage.getItem('token');
  const response = await fetch('/api/booking/unassigned', {
    method: 'GET',
    headers: {
      'Authorization': token ? `Bearer ${token}` : '',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch unassigned bookings');
  }
  return response.json();
};

const fetchBooking = async (bookingId) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`/api/booking/${bookingId}`, {
    method: 'GET',
    headers: {
      'Authorization': token ? `Bearer ${token}` : '',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch single booking');
  }
  return response.json();
};

const updateBookingDetails = async(booking) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`/api/booking/update/${booking._id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? `Bearer ${token}` : '',
    },
    body: JSON.stringify(booking),
  });
  if (!response.ok) {
    throw new Error('Failed to update booking details');
  }
  return response.json();
};

export { 
  fetchAssignedBookings,
  fetchUnassignedBookings, 
  fetchBooking,
  updateBookingDetails, 
};
